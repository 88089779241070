import { Component, Inject, OnInit, AfterViewInit } from '@angular/core';
import { SharedService } from 'src/app/shared.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AddEditPersonalComponent } from './add-edit-personal/add-edit-personal.component';
import {
  MatDialog,
  MatDialogRef,
   MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  trigger,
  state,
  style,
  animate,
  transition,
} from '@angular/animations';

@Component({
  selector: 'app-personal',
  templateUrl: './personal.component.html',
  styleUrls: ['./personal.component.css'],
  animations: [
    trigger('fadeIn', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('700ms ease-in', style({ opacity: 1 })),
      ]),
    ]),
  ],
})
export class PersonalComponent implements OnInit, AfterViewInit {
  error: any;
  error2: any;

  constructor(
    private service: SharedService,
    private route: ActivatedRoute,
    private router: Router,
    // tslint:disable-next-line: variable-name
    public dialog: MatDialog
  ) {}

  PersonalList: any = [];
  PersonalListWithoutFilter: any = [];
  personal: any;
  firma: any;
  PersonalFilter = '';
  teilnehmerLister: any = [];

  ngOnInit(): void {
    this.refreshPersonalList();
    this.PersonalList = this.sortResult('Nachname', true);
  }

  ngAfterViewInit(): void {
  }

  // tslint:disable-next-line: typedef
  get authentification() {
    return localStorage.getItem('Token');
  }

  // tslint:disable-next-line: typedef
  refreshPersonalList() {
        this.service.getFirma().subscribe(
        (firma: any) => {this.firma = firma;
        });
        this.service.getPersonalList().subscribe(
          (listPersonal: any) => {
            this.PersonalList = listPersonal;
            this.PersonalListWithoutFilter = listPersonal;
            });
}



  // tslint:disable-next-line: typedef
  FilterFn() {
    const PersonalNachnameFilter = this.PersonalFilter;
    const PersonalVornameFilter = this.PersonalFilter;

    // tslint:disable-next-line: typedef
    this.PersonalList = this.PersonalListWithoutFilter.filter((el: any) => {
      return (
        el.Nachname.toString()
          .toLowerCase()
          .includes(PersonalNachnameFilter.toString().trim().toLowerCase()) ||
        el.Vorname.toString()
          .toLowerCase()
          .includes(PersonalVornameFilter.toString().trim().toLowerCase())
      );
      // console.log(el.Nachname);
    });
  }

  // tslint:disable-next-line: typedef
  convertDate(date: string) {
    if (date !== '-' && date !== undefined) {
      const [year, month, days] = date.split('-');
      return days + '.' + month + '.' + year;
    } else {
      return date;
    }
  }


  // tslint:disable-next-line: typedef
  convertDateForSort(date: any, checkedOut: any) {
    if(!checkedOut){
      return '0000-00-00'
    }
    else{
    return date === null ? '0000-00-00' : date;
    }
  }

  // tslint:disable-next-line: typedef
  sortResult(prop: any, asc: any) {
    // tslint:disable-next-line: typedef
    this.PersonalList = this.PersonalListWithoutFilter.sort(
      (a: any, b: any) => {
        if (asc) {
          return a[prop] > b[prop] ? 1 : a[prop] < b[prop] ? -1 : 0;
        } else {
          return b[prop] > a[prop] ? 1 : b[prop] < a[prop] ? -1 : 0;
        }
      }
    );
  }
  // tslint:disable-next-line: typedef
  sortResultDatum(prop: any, asc: any, checkedOut: any) {
    // tslint:disable-next-line: typedef
    this.PersonalList = this.PersonalListWithoutFilter.sort(
      (a: any, b: any) => {
        if (asc) {
          return this.convertDateForSort(a[prop], a[checkedOut]) >
            this.convertDateForSort(b[prop], b[checkedOut])
            ? 1
            : this.convertDateForSort(a[prop], a[checkedOut]) <
              this.convertDateForSort(b[prop], b[checkedOut])
            ? -1
            : 0;
        } else {
          return this.convertDateForSort(b[prop], b[checkedOut]) >
            this.convertDateForSort(a[prop], a[checkedOut])
            ? 1
            : this.convertDateForSort(b[prop], b[checkedOut]) <
              this.convertDateForSort(a[prop], a[checkedOut])
            ? -1
            : 0;
        }
      }
    );
  }

  // tslint:disable-next-line: typedef
  openDialog() {
    const dialogRef = this.dialog.open(AddEditPersonalComponent, {
      data: { Personal: { TnID: 0 }, Firma: this.firma },
      height: '90%',
    });
    dialogRef.afterClosed().subscribe((result) => {});
  }
  // tslint:disable-next-line: typedef
  openDialog2(teilnehmer: any) {
    const dialogRef = this.dialog.open(SendReminder, {
      data: { Personal: teilnehmer },
    });
    dialogRef.afterClosed().subscribe((result) => {});
  }

  // tslint:disable-next-line: typedef
  openDialog3(teilnehmer: any) {
    const dialogRef = this.dialog.open(DeletePersonal, {
      data: { Personal: teilnehmer },
    });
    dialogRef.afterClosed().subscribe((result) => {
      // this.refreshPersonalList();
    });
  }
}

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'dialog-sendreminder',
  templateUrl: 'sendreminder.html',
})
// tslint:disable-next-line: component-class-suffix
export class SendReminder {
  constructor(
    public dialogRef: MatDialogRef<SendReminder>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private service: SharedService,
    // tslint:disable-next-line: variable-name
    private _snackBar: MatSnackBar
  ) {}
  Teilehmer: any;

  // tslint:disable-next-line: typedef
  sendReminder(teilnehmer: any) {
    this.dialogRef.close();
    this.openSnackBar('Eine Erinnerung wurde verschickt.', false);
    // tslint:disable-next-line: only-arrow-functions
    // window.setTimeout( (f: any) => location.reload() , 2000);
  }

  onNoClick(): void {
    this.dialogRef.close();
    this.openSnackBar('Die Erinnerung wurde abgebrochen', true);
  }

  // tslint:disable-next-line: typedef
  openSnackBar(message: string, warn: boolean) {
    if (warn) {
      this._snackBar.open(message, '', {
        horizontalPosition: 'right',
        verticalPosition: 'top',
        duration: 3 * 1000,
        panelClass: ['warn-snackbar'],
      });
    } else {
      this._snackBar.open(message, '', {
        horizontalPosition: 'right',
        verticalPosition: 'top',
        duration: 3 * 1000,
        panelClass: ['green-snackbar'],
      });
    }
  }
}

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'dialog-deletePersonal',
  templateUrl: 'deletePersonal.html',
})
// tslint:disable-next-line: component-class-suffix
export class DeletePersonal {
  constructor(
    public dialogRef: MatDialogRef<DeletePersonal>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private service: SharedService,
    // tslint:disable-next-line: variable-name
    private _snackBar: MatSnackBar
  ) {}
  Teilehmer: any;

  // tslint:disable-next-line: typedef
  deletePersonal(personalID: any) {
    //this.service.deletePersonal(personalID).subscribe();
    this.dialogRef.close();
    this.openSnackBar('Die Löschung war erfolgreich', false);
    // tslint:disable-next-line: only-arrow-functions
    // window.setTimeout( (f: any) => location.reload() , 2000);
  }

  onNoClick(): void {
    this.dialogRef.close();
    this.openSnackBar('Die Löschung wurde abgebrochen', true);
  }

  // tslint:disable-next-line: typedef
  openSnackBar(message: string, warn: boolean) {
    if (warn) {
      this._snackBar.open(message, '', {
        horizontalPosition: 'right',
        verticalPosition: 'top',
        duration: 3 * 1000,
        panelClass: ['warn-snackbar'],
      });
    } else {
      this._snackBar.open(message, '', {
        horizontalPosition: 'right',
        verticalPosition: 'top',
        duration: 3 * 1000,
        panelClass: ['green-snackbar'],
      });
    }
  }
}
