import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import urls from 'src/assets/urls.json';

const token = localStorage.getItem('key');
const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    // tslint:disable-next-line: object-literal-key-quotes
    Authorization: 'Bearer ' + token,
  }),
};

@Injectable({
  providedIn: 'root',
})
export class SharedService {

  constructor(private http: HttpClient) {}

  // tslint:disable-next-line: typedef
  isTokenExpired(token: string) {
    const expiry = JSON.parse(atob(token.split('.')[1])).exp;
    // console.log(expiry * 1000);
    // console.log(Date.now());
    return expiry * 1000 < Date.now();
  }

  // tslint:disable-next-line: typedef
  getverfuegbareKurseList() {
    const body = {
      action: 'buchbareKurse',
    };

    return this.http.post(urls.APIUrlfirmen, body, httpOptions);
  }

    // tslint:disable-next-line: typedef
    getvergangeneKurseList() {
      const body = {
        action: 'vergangeneKurse',
      };
  
      return this.http.post(urls.APIUrlfirmen, body, httpOptions);
    }

  // tslint:disable-next-line: typedef
  getKursDetailById(id: any) {
    const body = {
      action: 'detailKurse',
      kursID: id,
    };
    // tslint:disable-next-line: object-literal-shorthand
    return this.http.post(urls.APIUrlfirmen, body, httpOptions);
  }

   // tslint:disable-next-line: typedef
   getVergangeneKursDetailById(id: any) {
    const body = {
      action: 'getKursDetailsVergangeneByID',
      kursID: id,
    };
    // tslint:disable-next-line: object-literal-shorthand
    return this.http.post(urls.APIUrlfirmen, body, httpOptions);
  }


  getTeilnehmendeKurs(id: any) {
    const body = {
      action: 'erfolgreichTeilgenommen',
      kursID: id,
    };
    // tslint:disable-next-line: object-literal-shorthand
    return this.http.post(urls.APIUrlfirmen, body, httpOptions);
  }
  // tslint:disable-next-line: typedef
  getPersonalListZumKursAngemeldet(kursid: any) {
    const body = {
      action: 'personalZumKursAngemeldet',
      kursID : kursid,
    };
    // tslint:disable-next-line: object-literal-shorthand
    return this.http.post(urls.APIUrlfirmen, body, httpOptions);
  }


  // tslint:disable-next-line: typedef
  getPersonalList() {
    const body = {
      action: 'getPersonal',
    };
    // tslint:disable-next-line: object-literal-shorthand
    return this.http.post(urls.APIUrlfirmen, body, httpOptions);
  }


  // tslint:disable-next-line: typedef
  getPersonalListFirmenGueltigEH() {
    const body = {
      action: 'getMitarbeiterGueltigEH',
    };
    // tslint:disable-next-line: object-literal-shorthand
    return this.http.post(urls.APIUrlfirmen, body, httpOptions);
  }

 // tslint:disable-next-line: typedef
 getPersonalListFirmenGueltigEHFrist() {
  const body = {
    action: 'getMitarbeiterGueltigEHFrist',
  };
  // tslint:disable-next-line: object-literal-shorthand
  return this.http.post(urls.APIUrlfirmen, body, httpOptions);
}

  // tslint:disable-next-line: typedef
  getFirma() {
    const body = {
      action: 'getFirma',
    };
    // tslint:disable-next-line: object-literal-shorthand
    return this.http.post(urls.APIUrlfirmen, body, httpOptions);
  }

    // tslint:disable-next-line: typedef
    getFirmaInterneKurse() {
      const body = {
        action: 'getFirmaInterneKurse',
      };
      // tslint:disable-next-line: object-literal-shorthand
      return this.http.post(urls.APIUrlfirmen, body, httpOptions);
    }


  // tslint:disable-next-line: typedef
  getAnzahlTeilnahmenKurs(id: any) {
    const body = { action: 'anzahl', kursID: id };
    // tslint:disable-next-line: object-literal-shorthand
    return this.http.post(urls.APIUrlfirmen, body, httpOptions);
  }

  // tslint:disable-next-line: typedef
  getTeilnahmenByTeilnehmer(id: any) {
    const body = { action: 'teilnahmen', tnID: id };
    // tslint:disable-next-line: object-literal-shorthand
    return this.http.post(urls.APIUrlfirmen, body, httpOptions);
  }

  // tslint:disable-next-line: typedef
  getTeilnehmerById(id: any) {
    const body = {
      action: 'detailTeilnehmer',
      tnID: id,
    };
    // tslint:disable-next-line: object-literal-shorthand
    return this.http.post(urls.APIUrlfirmen, body, httpOptions);
  }

  // tslint:disable-next-line: typedef
  getGliederung() {
    const body = {
      action: 'getGliederung',
    };
    // tslint:disable-next-line: object-literal-shorthand
    return this.http.post(urls.APIUrlfirmen, body, httpOptions);
  }



  // tslint:disable-next-line: object-literal-shorthand
  // tslint:disable-next-line: typedef
  getUser() {
    const body = {
      action: 'getUser',
    };
    // tslint:disable-next-line: object-literal-shorthand
    return this.http.post(urls.APIUrlfirmen, body, httpOptions);
  }

  // tslint:disable-next-line: typedef
  getTeilnahmenAnzahl(kurs: any) {
    const body = {
      action: 'getAnzahl',
      kursID: kurs,
    };
    // tslint:disable-next-line: object-literal-shorthand
    return this.http.post(urls.APIUrlfirmen, body, httpOptions);
  }


  // tslint:disable-next-line: typedef
  getZukuenftigeKurse(personalID: any){
    const body = {
      action: 'getZukuenftigeKurseTeilnehmer',
      tnID: personalID,
    };
    // tslint:disable-next-line: object-literal-shorthand
    return this.http.post(urls.APIUrlfirmen, body, httpOptions);

  }
  // tslint:disable-next-line: typedef
  getTeilnahmenKurs(kurs: any, fmId: any) {
    const body = {
      action: 'getTeilnahmeKurs',
      kursID: kurs,
    };

    // tslint:disable-next-line: object-literal-shorthand
    return this.http.post(urls.APIUrlfirmen, body, httpOptions);
  }

  // tslint:disable-next-line: typedef
  updateTeilnehmer(val: any) {
    const body = {
      action: 'updateTeilnehmer',
      data: val,
    };
    return this.http.post(urls.APIUrlfirmen, body, httpOptions);
  }

  // tslint:disable-next-line: typedef
  addTeilnehmer(val: any) {
    const body = {
      action: 'addTeilnehmer',
      data: val,
    };
    return this.http.post(urls.APIUrlfirmen, body, httpOptions);
  }

  // tslint:disable-next-line: typedef
  addBuchung(kursid: any, tnid: any) {
    const body = {
      action: 'kursBuchen',
      kursID: kursid,
      tnID: tnid,
    };
    return this.http.post(urls.APIUrlfirmen, body, httpOptions);
  }

  // tslint:disable-next-line: typedef
  removeBuchung(kursid: any, tnid: any) {
    const body = {
      action: 'kursAbmelden',
      kursID: kursid,
      tnID: tnid,
    };
    return this.http.post(urls.APIUrlfirmen, body, httpOptions);
  }

  // tslint:disable-next-line: typedef
  zeigeGebuchteKurse() {
    const body = {
      action: 'zeigeGebuchteKurse',
    };
    return this.http.post(urls.APIUrlfirmen, body, httpOptions);
  }

  // tslint:disable-next-line: typedef
  getPersonalListNichtGebucht(kursid: any) {
    const body = {
      action: 'getPersonalNichtGebucht',
      kursID: kursid,
    };
    // tslint:disable-next-line: object-literal-shorthand
    return this.http.post(urls.APIUrlfirmen, body, httpOptions);
  }


  // tslint:disable-next-line: typedef
  logOutAccesslog()
  {
    const body = {
      action: 'addLogoutlog',
    };
    return this.http.post(urls.APIUrlfirmen, body, httpOptions);
  }
}
