import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {
  UntypedFormGroup,
  FormBuilder,
  Validators,
  NgForm,
} from '@angular/forms';
import { first } from 'rxjs/operators';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { LoginService } from '../login.service';
import { SharedService } from '../shared.service';
import urls from 'src/assets/urls.json';
import {
  trigger,
  state,
  style,
  animate,
  transition,
} from '@angular/animations';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
  animations: [
    trigger('fadeIn', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('700ms ease-in', style({ opacity: 1 })),
      ]),
    ]),
  ],
})
export class LoginComponent implements OnInit {
  angForm: UntypedFormGroup;
  fehlermeldung = false;
  constructor(
    private fb: FormBuilder,
    private service: LoginService,
    public dialog: MatDialog,
    private fmService: SharedService,
    private http: HttpClient
  ) {
    this.angForm = this.fb.group({
      email: [
        '',
        [Validators.required, Validators.minLength(1), Validators.email],
      ],
      password: ['', Validators.required],
    });
  }

  ngOnInit(): void {}

  // tslint:disable-next-line: typedef
  postdata(angForm1: any) {
    this.service
      .userlogin(angForm1.value.email, angForm1.value.password)
      .pipe(first())
      .subscribe(
        (data: any) => {
          console.log(data);
          localStorage.setItem('key', data.jwt);
          window.setTimeout(() => {
            location.reload();
          }, 1000);
          // const redirect = this.service.redirectUrl
          //     ? this.service.redirectUrl
          //     : '/home';
          // this.router.navigate([redirect]);
        },
        (error: any) => {
          if (error.error.error === 'Unauthorized') {
            this.angForm.controls.email.setValue(null);
            this.angForm.controls.password.setValue(null);
            this.fehlermeldung = true;
          }
        }
      );
  }

  // tslint:disable-next-line: typedef
  get email() {
    return this.angForm.get('email');
  }
  // tslint:disable-next-line: typedef
  get password() {
    return this.angForm.get('password');
  }

  // tslint:disable-next-line: typedef
  openDatenschutz() {
    window.open(urls.datenschutz, '_blank');
  }

  // tslint:disable-next-line: typedef
  openImpressum() {
    window.open(urls.impressum, '_blank');
  }

  // tslint:disable-next-line: typedef
  get authentification() {
    return localStorage.getItem('token');
  }

  getMessage() {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    return this.http
      .post('http://localhost:3000/api/message', {}, { headers })
      .subscribe((data: any) => {
        console.log(data);
      });
  }
}
