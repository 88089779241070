import { Component, OnInit, Inject } from '@angular/core';
import { SharedService } from 'src/app/shared.service';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import {MatSnackBar } from '@angular/material/snack-bar';
import {
  trigger,
  state,
  style,
  animate,
  transition,
} from '@angular/animations';

@Component({
  selector: 'app-zeige-gebuchtekurse',
  templateUrl: './zeige-gebuchtekurse.component.html',
  styleUrls: ['./zeige-gebuchtekurse.component.css'],
  animations: [
    trigger('fadeIn', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('700ms ease-in', style({ opacity: 1 })),
      ]),
    ]),
  ],
})
export class ZeigeGebuchtekurseComponent implements OnInit {
  constructor(private service: SharedService, public dialog: MatDialog) {}

  KursList: any = [];
  KursListWithoutFilter: any = [];
  anzahlDerKursteilnehmer: any;

  ngOnInit(): void {
    this.refreshKursList();
  }

  // tslint:disable-next-line: typedef
  refreshKursList() {
    this.service.zeigeGebuchteKurse().subscribe((data: any) => {
      if (data.anzahl === 0) {
        this.anzahlDerKursteilnehmer = data.anzahl;
        console.log(data.anzahl);
      } else {
        this.anzahlDerKursteilnehmer = data.length;
        this.KursList = data;
        this.KursListWithoutFilter = data;
      }
    });
  }

  openDialog(teilnehmerid: any, kursid: any): void {
    const dialogRef = this.dialog.open(DialogAbmeldenDialog, {
      data: { tnID: teilnehmerid, kursID: kursid },
    });
    dialogRef.afterClosed().subscribe((result) => {
      this.ngOnInit();
    });
  }

  // tslint:disable-next-line: typedef
  convertTime(time: string) {
    return time.substring(0, time.length - 3);
  }

  // tslint:disable-next-line: typedef
  convertDate(date: any) {
    if (date != null) {
      const [year, month, days] = date.split('-');
      return days + '.' + month + '.' + year;
    } else {
      return;
    }
  }
}

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'dialog-abmelden-dialog',
  templateUrl: 'dialog-abmelden.html',
})
// tslint:disable-next-line: component-class-suffix
export class DialogAbmeldenDialog {
  constructor(
    public dialogRef: MatDialogRef<DialogAbmeldenDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private service: SharedService,
    // tslint:disable-next-line: variable-name
    private _snackBar: MatSnackBar
  ) {}
  Teilnahmen: any = [];
  teilnahmeID = 0;
  // tslint:disable-next-line: typedef
  delete(kursID: any, teilnID: any) {
    this.service.removeBuchung(kursID, teilnID).subscribe((data: any) => {});
    this.dialogRef.close();
    this.openSnackBar('Die Buchung wurde storniert', false);
  }

  onNoClick(): void {
    this.dialogRef.close();
    this.openSnackBar('Die Stornierung wurde abgebrochen', true);
  }

  // tslint:disable-next-line: typedef
  openSnackBar(message: string, warn: boolean) {
    if (warn) {
      this._snackBar.open(message, '', {
        horizontalPosition: 'right',
        verticalPosition: 'top',
        duration: 5 * 1000,
        panelClass: ['warn-snackbar'],
      });
    } else {
      this._snackBar.open(message, '', {
        horizontalPosition: 'right',
        verticalPosition: 'top',
        duration: 5 * 1000,
        panelClass: ['green-snackbar'],
      });
    }
  }
}
